<template>
  <h1></h1>
</template>
<script>
import state from '@/store';
import router, { rolesMap } from '@/router';
import store from '@/store';

export default {
  name: 'SetPassword',
  created() {
    let token = this.$route.query.token;
    let password = this.$route.query.password;
    if (!token || !password) {
      this.$router.push({ path: '/login' });
    } else {
      this.$store
        .dispatch('auth/verifyUserAndSetPassword', {
          token: token,
          password: password,
        })
        .then((result) => {
          this.$store
            .dispatch('auth/login', { username: result.data.username, password: this.$route.query.password })
            .then((res) => {
              window.location.reload();
              if (res.user.role_id !== 3) {
                res.user.role_id = parseInt(res.user.role.id);
                state.dispatch('auth/setUser', res.user);
                state.dispatch('auth/setRole', res.user);
                state.dispatch('auth/setAuthToken', res.access_token);
                state.dispatch('auth/setAuthenticated', true);
                if (res.user.language) {
                  localStorage.setItem('currentLanguage', res.user.language);
                  this.$store.dispatch('language/changeLanguage', res.user.language);
                }
                res.user.role_id !== 1 && state.dispatch('auth/setSubscription', res.subscription);
                res.user.role_id !== 1 && state.dispatch('auth/setCustomer', res.customer);
                if (parseInt(res.user?.show_welcome_modal) === 1) {
                  state.dispatch('auth/setWelcomeModal', true);
                }
                const userRole = rolesMap.find((e) => e.id === parseInt(store.getters['auth/role']));
                if (this.$deviceToken) {
                  // eslint-disable-next-line no-console
                  this.submitToken(this.$deviceToken).catch(() => console.log('error saving token'));
                }
                router.push({ name: userRole.redirect }).catch((err) => {
                  if (
                    err.name !== 'NavigationDuplicated' &&
                    !err.message.includes('Avoided redundant navigation to current location')
                  ) {
                    // eslint-disable-next-line no-console
                    console.log(err);
                  }
                });
                localStorage.setItem('loggedIn', true);
                this.$router.push({ path: '/' });
              }
            });
        });
    }
  },
  methods: {
    async submitToken(token) {
      await this.$store.dispatch('firebaseToken/submitToken', { token });
    },
  },
};
</script>
